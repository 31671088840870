import React from "react"

import { getItem } from "../../util/functions"
import ConfirmationComponent from "./ConfirmationComponent"
const TreatmentFormConfirmation = () => {
  return (
    <ConfirmationComponent
      firstName={getItem("firstName")}
      lastName={getItem("lastName")}
      practice={getItem("practice")}
      treatment={getItem("treatmentName")}
      ctaUrl={process.env.BASE}
    />
  )
}

export default TreatmentFormConfirmation
