import React from "react"
import SpecificTreatmentFormConfirmation from "../components/Form/SpecificTreatmentFormConfirmation"
import Layout from "../helper/layout"

const TreatmentFormConfirmationPage = () => {
  return (
    <Layout>
      <SpecificTreatmentFormConfirmation />
    </Layout>
  )
}

export default TreatmentFormConfirmationPage
